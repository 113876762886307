:root {
  --height-about-1920: 840px;
  --height-about-1680: 735px;
  --height-about-1440: 630px;
  --height-about-1280: 560px;
  --height-about-1024: 448px;
}

.about-us {
  background-image: url("/src/assets/images/about-us.jpg");
  background-size: cover;
  height: var(--height-about-1920);
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.w-50 {
  width: 50%;
  display: block;
}

.about-us-text {
  width: 50%;
  font-size: var(--font-text-1920);
  color: #4d4b4b;
  padding-right: var(--padding-x-1920);
}

.about-us-heading {
  font-size: var(--font-header-1920);
}

@media only screen and (max-width: 1680px) {
  .about-us {
    height: var(--height-about-1680);
  }

  .about-us-heading {
    font-size: var(--font-header-1680);
  }

  .about-us-text {
    font-size: var(--font-text-1680);
    padding-right: var(--padding-x-1680);
  }
}

@media only screen and (max-width: 1440px) {
  .about-us {
    height: var(--height-about-1440);
  }

  .about-us-heading {
    font-size: var(--font-header-1440);
  }

  .about-us-text {
    font-size: var(--font-text-1440);
    padding-right: var(--padding-x-1440);
  }
}

@media only screen and (max-width: 1280px) {
  .about-us {
    height: var(--height-about-1280);
  }

  .about-us-heading {
    font-size: var(--font-header-1280);
  }

  .about-us-text {
    font-size: var(--font-text-1280);
    padding-right: var(--padding-x-1280);
  }
}

@media only screen and (max-width: 1024px) {
  .about-us {
    height: var(--height-about-1024);
  }

  .about-us-heading {
    font-size: var(--font-header-1024);
  }

  .about-us-text {
    font-size: var(--font-text-1024);
    padding-right: var(--padding-x-1024);
  }
}

@media only screen and (max-width: 768px) {
  .w-50 {
    display: none;
  }

  .about-us {
    align-items: flex-start;
    background-image: url("/src/assets/images/about-us-mobile.jpeg");
    background-position: center;
  }

  .about-us-text {
    margin-top: 30px;
    padding: 0;
    width: 80%;
    text-align: center;
  }
}