
:root {
  --height-nailbox-banner-1920: 840px;
  --height-nailbox-banner-1680: 750px;
  --height-nailbox-banner-1440: 650px;
  --height-nailbox-banner-1280: 550px;
  --height-nailbox-banner-1024: 450px;
}

.nailbox-banner {
  height: var(--height-nailbox-banner-1920);
  width: 100%;
  background-image: url(/src/assets/images/nailbox-banner.png);
  background-size: cover;
}

.nailbox-button {
  width: 50%;
  position: relative;
  top: 62.5%;
  left: 11%;
}

.nailbox-button button:nth-child(1) {
  margin-right: 1.5%;
}

.nailbox-button > * {
  padding-left: 2%;
  padding-right: 2%;
}

@media only screen and (max-width: 1680px) {
  .nailbox-banner {
    height: var(--height-nailbox-banner-1680);
  }

  .nailbox-button { 
    top: 62.5%;
    left: 11.3%;
    /* width: 339.5px; */
  }
}

@media only screen and (max-width: 1440px) {
  .nailbox-banner {
    height: var(--height-nailbox-banner-1440);
  }

  .nailbox-button { 
    top: 62%;
    left: 11.4%;
  }
}

@media only screen and (max-width: 1280px) {
  .nailbox-banner {
    height: var(--height-nailbox-banner-1280);
  }

  .nailbox-button {
    top: 63%;
    left: 10.9%;
  }
}

@media only screen and (max-width: 1024px) {
  .nailbox-banner {
    height: var(--height-nailbox-banner-1024);
  }

  .nailbox-button {
    top: 62%;
    left: 10.9%;
  }
}

@media only screen and (min-width: 1921px) {
  .nailbox-banner {
    height: 1200px;
  }

  .nailbox-button {
    left: 11.8%;
  }
}

@media only screen and (max-width: 768px) {
  .nailbox-button {
    width: 100%;
    text-align: center;
    top: 15%;
    left: 0;
  }

  .nailbox-banner {
    background-image: url(/src/assets/images/nailbox-banner-mobile.png);
    background-size: cover;
    background-position: center;
  }
}