.footer-container {
  background-color: #30d5c8;
  color: white;
  padding: 80px 116px 80px 116px;
  /* height: 440px; */
}

.footer-sections {
  display: flex;
  justify-content: space-between;
}

.footer-section:nth-child(2) {
  position: relative;
}

.footer-section:nth-child(3) {
  position: relative;
}

.footer-section {
  /* height: 240px; */
  flex-grow: 1;
}

.footer-section h1 {
  font-size: var(--font-sub-header-1920);
}

.footer-section p {
  font-size: var(--font-text-1920);
}

.footer-section small {
  font-size: var(--font-sm-1920);
}

.logos img {
  margin-right: 10px;
}

@media only screen and (min-width: 768px) {
  .footer-sections:nth-child(2) .footer-section {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 1680px) {
  .footer-container {
    /* height: 420px; */
    padding: 70px 101.5px 70px 101.5x;
  }

  .footer-section h1 {
    font-size: var(--font-sub-header-1680);
  }

  .footer-section p {
    font-size: var(--font-text-1680);
  }

  .footer-section small {
    font-size: var(--font-sm-1680);
  }
}

@media only screen and (max-width: 1440px) {
  .footer-container {
    /* height: 350px; */
    padding: 60px 87px 60px 87px;
  }

  .footer-section h1 {
    font-size: var(--font-sub-header-1440);
  }

  .footer-section p {
    font-size: var(--font-text-1440);
  }

  .footer-section small {
    font-size: var(--font-sm-1440);
  }
}

@media only screen and (max-width: 1280px) {
  .footer-container {
    /* height: 320px; */
    padding: 53px 77px 53px 77px;
  }


  .footer-section h1 {
    font-size: var(--font-sub-header-1280);
  }

  .footer-section p {
    font-size: var(--font-text-1280);
  }

  .footer-section small {
    font-size: var(--font-sm-1280);
  }
}

@media only screen and (max-width: 1024px) {
  .footer-container {
    /* height: 290px; */
    padding: 42px 62px 42px 62px;
  }

  .footer-section h1 {
    font-size: var(--font-sub-header-1024);
  }

  .footer-section p {
    font-size: var(--font-text-1024);
  }

  .footer-section small {
    font-size: var(--font-sm-1024);
  }
}

@media only screen and (max-width: 768px) {
  .footer-sections {
    flex-direction: column;
    justify-self: flex-start;
  }

  .footer-section {
    flex-direction: column;
    display: flex;
    width: 100% !important;
  }
  .footer-container {
    text-align: center;
  }

  .footer-sections:nth-child(1) .footer-section:nth-child(2),
  .footer-sections:nth-child(1) .footer-section:nth-child(3) {
    display: none;
    height: 0px;
  }

  .footer-sections:nth-child(2) .footer-section:nth-child(1){
    display: none;
  }

  .footer-sections:nth-child(2) .footer-section:nth-child(4){
    margin-top: 30px;
    display: block !important;
  }
}