:root {
  --height-about-1920: 840px;
  --height-about-1680: 816.67px;
  --height-about-1440: 700px;
  --height-about-1280: 622.22px;
  --height-about-1024: 497.77px;

  --font-action-1920: 100px;
  --font-action-1680: 87.5px;
  --font-action-1440: 75px;
  --font-action-1280: 66.67px;
  --font-action-1024: 53.33px;
}

.action {
  aspect-ratio: 1 / 1;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  color: #4d4d4d;
}

.action-text {
  font-size: var(--font-action-1920);

  line-height: 100%;
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.action-text div {
  margin-bottom: 10px;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
  
  width: 272px;
}

.bg-salon {
  background-image: url("/src/assets/images/book-salon.png");
  background-size: cover;
  background-position: center center;
}

.bg-nailbox {
  background-image: url("/src/assets/images/shop-nailbox.png");
  background-size: cover;
  background-position: center center;
}

#actions-container {
  display: flex;
  /* gap: 50px; */
}

@media only screen and (max-width: 1680px) {
  .action {
    /* height: var(--height-about-1680); */
  }

  .action-text {
    font-size: var(--font-action-1680);
  }

  .action-text div {
    margin-bottom: 8.75px;
  }

  .action-button {
    width: 238px;
    margin-top: 30.625px;
  }
}

@media only screen and (max-width: 1440px) {
  .action {
    /* height: var(--height-about-1440); */
  }

  .action-text {
    font-size: var(--font-action-1440);
  }

  .action-text div {
    margin-bottom: 7.5px;
  }

  .action-button {
    width: 204px;
    margin-top: 26.25px;
  }
}

@media only screen and (max-width: 1280px) {
  .action {
    /* height: var(--height-about-1280); */
  }

  .action-text {
    font-size: var(--font-action-1280);
  }

  .action-text div {
    margin-bottom: 6.67px;
  }

  .action-button {
    width: 181.33px;
    margin-top: 23.33px;
  }
}

@media only screen and (max-width: 1024px) {
  .action {
    /* height: var(--height-about-1024); */
  }

  .action-text {
    font-size: var(--font-action-1024);
  }

  .action-text div {
    margin-bottom: 5.53px;
  }

  .action-button {
    width: 145px;
    margin-top: 18.67px;
  }
}

@media only screen and (max-width: 768px) {
  #actions-container {
    flex-direction: column;
  }

  .action {
    margin: 0 25px;
    background-origin: content-box;
  }

  .action:nth-child(1) {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .action-text {
    width: 100%;
  }
}