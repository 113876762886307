:root {
  --height-top-bar-1920: 217px;
  --height-top-bar-1680: 190px;
  --height-top-bar-1440: 162.75px;
  --height-top-bar-1280: 144.67px;
  --height-top-bar-1024: 115.7px;
  --height-top-bar-768: 80px;

  --width-img-1920: 150px;
  --width-img-1680: 131.25px;
  --width-img-1440: 112.5px;
  --width-img-1280: 100px;
  --width-img-1024: 80px;
  --width-img-768: 60px;
}

.top-bar {
  font-size: var(--font-sub-header-1920);
  margin: 20px 0;
  text-align: right;
  padding: 0 var(--padding-x-1920);
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  color: #4b4d4d;
}

#responsive-navbar-nav {
  justify-content: flex-end;
}

.top-bar-item {
  margin: 0 20px;
}

.top-bar img {
  width: var(--width-img-1920);
}

.booking-btn {
  background-color: #30D5C8;
  border-color: #30D5C8;
  font-family: 'Verdana';
  font-size: inherit;
  color: white;
  border-radius: 25px;
  padding-left: 30px;
  padding-right: 30px;
}

.booking-btn:hover, .booking-btn:focus, .booking-btn:active {
  background-color: #ef6940;
  border-color: #ef6940;
}

.nav-link {
  color: inherit;
}

.nav-link:hover {
  color: #30D5C8;
}

#alt-booking-btn {
  display: none;
}

@media only screen and (max-width: 1680px) {
  .top-bar {
    padding: 0 var(--padding-x-1680);
    font-size: var(--font-sub-header-1680);
  }

  .top-bar img {
    width: var(--width-img-1680);
  }
}

@media only screen and (max-width: 1440px) {
  .top-bar {
    padding: 0 var(--padding-x-1440);
    font-size: var(--font-sub-header-1440);
  }

  .top-bar img {
    width: var(--width-img-1440);
  }
}

@media only screen and (max-width: 1280px) {
  .top-bar {
    padding: 0 var(--padding-x-1280);
    font-size: var(--font-sub-header-1280);
  }

  .top-bar img {
    width: var(--width-img-1280);
  }
}

@media only screen and (max-width: 1024px) {
  .top-bar {
    padding: 0 var(--padding-x-1024);
    font-size: var(--font-sub-header-1024);
  }

  .top-bar img {
    width: var(--width-img-1024);
  }

  .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
}

#top-bar-links {
  display: flex;
  width: 60%;
  justify-content: space-between;
}


@media only screen and (max-width: 768px) {
  .top-bar {
    padding: 0 var(--padding-x-768);
  }

  .top-bar img {
    width: var(--width-img-768);
  }

  #top-bar-links {
    display: flex;
    justify-content: center;
  }

  .top-bar {
    text-align: center;
  }

  .top-bar-item {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }

  .top-bar-item:last-child {
    border: none;
  }

  .booking-btn {
    display: none;
  }

  #alt-booking-btn {
    display: block;
  }
}