:root {
  --height-svc-1920: 384px;
  --height-svc-1680: 336px;
  --height-svc-1440: 256px;
  --height-svc-1280: 256px;
  --height-svc-1024: 204.8px;
}

.services-container {
  /* background-color: yellow; */
  height: var(--height-svc-1920);
  padding: 0 var(--padding-x-1920);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.services {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.services-text {
  font-size: var(--font-header-1920);
  margin-bottom: 60px;

  line-height: 100%;
  display: block;
  text-align: center;
  color: #4D4B4B;
}


@media only screen and (max-width: 1680px) {
  .services-container {
    padding: 0 var(--padding-x-1680);
    height: var(--height-svc-1680);
  }

  .services-text {
    font-size: var(--font-header-1680);
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 1440px) {
  .services-container {
    padding: 0 var(--padding-x-1440);
    height: var(--height-svc-1440);
  }

  .services-text {
    font-size: var(--font-header-1440);
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 1280px) {
  .services-container {
    padding: 0 var(--padding-x-1280);
    height: var(--height-svc-1280);
  }

  .services-text {
    font-size: var(--font-header-1280);
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .services-container {
    padding: 0 var(--padding-x-1024);
    height: var(--height-svc-1024);
  }

  .services-text {
    font-size: var(--font-header-1024);
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .services-container {
    padding: 0 var(--padding-x-768);
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .services {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }
}