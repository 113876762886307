.contact-container {
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-x-1920);
  color: #4D4B4B;
  /* margin-top: 30px; */
}

.contact-address-map {
  display: flex;
  flex-direction: row;
  height: 400px;
}

.contact-header {
  text-align: center;
  font-size: var(--font-header-1920);
  margin-bottom: 50px;
}

.contact-divider {
  height: 2px;
  width: 450px;
}

.contact-address {
  width: 50%;
}

.contact-map {
  width: 50%;
}

.contact-map img {
  max-height: 100%;
  float: right;
}

.text-header {
  display: block;
  font-size: var(--font-sub-header-1920);
  margin-top: 30px;
}

.text-line {
  display: block;
  font-size: var(--font-text-1920);
  margin-top: 10px;
  margin-bottom: 20px;
}

.contact-button {
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (max-width: 1680px) {
  .contact-address-map {
    height: 370px;
  }

  .contact-divider {
    width: 380px;
  }

  .contact-container {
    padding: 0 var(--padding-x-1680);
  }

  .contact-header {
    margin-bottom: 40px;
    font-size: var(--font-header-1680);
  }

  .text-header {
    font-size: var(--font-sub-header-1680);
    /* margin-top: 25px; */
  }

  .text-line {
    font-size: var(--font-text-1680);
    /* margin-top: 20px;
    margin-bottom: 47px; */
  }
}

@media only screen and (max-width: 1440px) {
  .contact-address-map {
    height: 330px;
  }

  .contact-container {
    padding: 0 var(--padding-x-1440);
  }

  .contact-header {
    margin-bottom: 30px;
    font-size: var(--font-header-1440);
  }

  .text-header {
    font-size: var(--font-sub-header-1440);
    /* margin-top: 20px; */
  }

  .text-line {
    font-size: var(--font-text-1440);
    /* margin-top: 25px;
    margin-bottom: 32px; */
  }

  .contact-divider {
    width: 330px;
  }
}

@media only screen and (max-width: 1280px) {
  .contact-address-map {
    height: 310px;
  }

  .contact-container {
    padding: 0 var(--padding-x-1280);
  }

  .contact-header {
    margin-bottom: 20px;
    font-size: var(--font-header-1280);
  }

  .text-header {
    font-size: var(--font-sub-header-1280);
    /* margin-top: 20px; */
  }

  .text-line {
    font-size: var(--font-text-1280);
    /* margin-top: 20px;
    margin-bottom: 28px; */
  }

  .contact-divider {
    width: 300px;
  }
}

@media only screen and (max-width: 1024px) {
  .contact-address-map {
    height: 275px;
  }

  .contact-container {
    padding: 0 var(--padding-x-1024);
  }

  .contact-header {
    margin-bottom: 15px;
    font-size: var(--font-header-1024);
  }

  .text-header {
    font-size: var(--font-sub-header-1024);
    /* margin-top: 25px; */
  }

  .text-line {
    font-size: var(--font-text-1024);
    /* margin-top: 15px;
    margin-bottom: 22px; */
  }

  .contact-divider {
    width: 240px;
  }
}

@media only screen and (max-width: 768px) {
  .contact-address-map {
    flex-direction: column;
    text-align: center;
  }

  .contact-address {
    width: 100%;
  }

  .contact-map {
    margin-top: 20px;
    width: 100%;
  }
  
  .contact-container {
    height: 500px;
  }
}