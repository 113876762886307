.jn-button, .jn-button:visited {
  border: #30D5C8 solid 3px;
  background: none;
  color: #30D5C8;
  font-size: var(--font-btn-1920);
  font-family: "Verdana";
  padding-top: 13px;
  padding-bottom: 13px;
}

.jn-button:hover, .jn-button:focus, .jn-button:active{
  color: #ffffff;
  background-color: #76F2EA;
  border: #76F2EA solid 3px;
}

@media only screen and (max-width: 1680px) {
  .jn-button {
    font-size: var(--font-btn-1680);
    padding-top: 11.375px;
    padding-bottom: 11.375px;
  }
}

@media only screen and (max-width: 1440px) {
  .jn-button {
    font-size: var(--font-btn-1440);
    padding-top: 9.75px;
    padding-bottom: 9.75px;
  }
}

@media only screen and (max-width: 1280px) {
  .jn-button {
    font-size: var(--font-btn-1280);
    padding-top: 8.67px;
    padding-bottom: 8.67px;
  }
}

@media only screen and (max-width: 1024px) {
  .jn-button {
    font-size: var(--font-btn-1024);
    padding-top: 6.93px;
    padding-bottom: 6.93px;
  }
}