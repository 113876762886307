.carousel-control-next-icon {
  border-radius: 50%;
  background-color: gray;
  background-image: url("/src/assets/images/carousel-next.png");
}

.carousel-control-prev-icon {
  border-radius: 50%;
  background-color: gray;
  background-image: url("/src/assets/images/carousel-prev.png");
}

.banner {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .banner {
    height: 300px;
    object-fit: cover;
  }
}