.instagram-header {
  font-size: var(--font-header-1920);
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
  color: #4d4b4b;
  
}

#instagram-sub-header {
  padding: var(--padding-x-768);
}

.instagram-posts {
  display: flex;
  width: 100%;
  /* height: 750px; */
  aspect-ratio: 2.5 / 1;
}

.instagram-posts-secondary {
  display: flex;
  flex-direction: column;
}

.instagram-posts-secondary div {
  display: flex;
  /* justify-content: space-between; */
}

.ig-cell {
  width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1680px) {
  .instagram-posts {
    /* height: 650px; */
  }

  .instagram-header {
    font-size: var(--font-header-1680);
  }
}

@media only screen and (max-width: 1440px) {
  .instagram-posts {
    /* height: 550px; */
  }

  .instagram-header {
    font-size: var(--font-header-1440);
  }
}

@media only screen and (max-width: 1280px) {
  .instagram-posts {
    /* height: 500px; */
  }

  .instagram-header {
    font-size: var(--font-header-1280);
  }
}

@media only screen and (max-width: 1024px) {
  .instagram-posts {
    /* height: 400px; */
  }

  .instagram-header {
    font-size: var(--font-header-1024);
  }
}

@media only screen and (max-width: 768px) {
  .instagram-posts {
    width: 100%;
  }

  #instagram-sub-header {
    padding-top: 0px;
    padding-bottom: 10px;
    font-size: var(--font-sub-header-768);
  }
}