:root {
  --height-commit-1920: 384px;
  --height-commit-1680: 336px;
  --height-commit-1440: 300px;
  --height-commit-1280: 256px;
  --height-commit-1024: 204.8px;
}

.commitment-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 350px;
  height: var(--height-commit-1920);
  color: #4d4b4b;
}

.commitment-header {
  font-size: var(--font-header-1920);
  margin-bottom: 50px;
}

.commitments {
  display: flex;
  justify-content: space-around;
  font-size: var(--font-text-1920);
}

.commitment img {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.commitment:last-child img {
  width: 120px;
  height: 120px;
  margin-bottom: 0;
  position: relative;
  top: -10px;
}

@media only screen and (max-width: 1680px) {
  .commitment-container {
    height: var(--height-commit-1680);
  }

  .commitment-header {
    font-size: var(--font-header-1680);
    margin-bottom: 40px;
  }

  .commitments {
    font-size: var(--font-text-1680);
  }

  .commitment img {
    width: 90px;
    height: 90px;
  }

  .commitment:last-child img {
    width: 110px;
    height: 110px;
  }
}

@media only screen and (max-width: 1440px) {
  .commitment-container {
    padding: 0 300px;
    height: var(--height-commit-1440);
  }

  .commitment-header {
    font-size: var(--font-header-1440);
    margin-bottom: 30px;
  }

  .commitments {
    font-size: var(--font-text-1440);
  }

  .commitment img {
    width: 80px;
    height: 80px;
  }

  .commitment:last-child img {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 1280px) {
  .commitment-container {
    padding: 0 250px;
    height: var(--height-commit-1280);
  }

  .commitment-header {
    font-size: var(--font-header-1280);
    margin-bottom: 20px;
  }

  .commitments {
    font-size: var(--font-text-1280);
  }

  .commitment img {
    width: 70px;
    height: 70px;
  }

  .commitment:last-child img {
    width: 90px;
    height: 90px;
  }
}

@media only screen and (max-width: 1024px) {
  .commitment-container {
    padding: 0 200px;
    height: var(--height-commit-1024);
  }

  .commitment-header {
    font-size: var(--font-header-1024);
    margin-bottom: 15px;
  }

  .commitments {
    font-size: var(--font-text-1024);
  }

  .commitment img {
    width: 60px;
    height: 60px;
  }

  .commitment:last-child img {
    width: 80px;
    height: 80px;
  }
}

@media only screen and (max-width: 768px) {
  .commitment-container {
    padding: 0 50px;
    /* height: var(--height-commit-1024); */
  }
/* 
  .commitment-header {
    font-size: var(--font-header-1024);
    margin-bottom: 15px;
  }

  .commitments {
    font-size: var(--font-text-1024);
  }

  .commitment img {
    width: 60px;
    height: 60px;
  }

  .commitment:last-child img {
    width: 80px;
    height: 80px;
  } */
}