.service {
  display: flex;
  align-items: center;
  color: #4D4B4B;
  margin: 0 5px;
  width: 50%;
}

.service-description {
  margin-left: 20px;
  margin-top: 2.5%;

  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.service-text {
  font-size: var(--font-sub-header-1920);
}

.service-price {
  font-size: var(--font-sm-1920);
}

.service > div:nth-child(1) {
  border-radius: 28%;
  border: 1px solid grey;
  background-color: grey;
}

.service img {
  height: 100%;
}

@media only screen and (max-width: 1680px) {
  .service-text {
    font-size: var(--font-sub-header-1680);
  }

  .service-description {
    margin-top: 4px;
    margin-left: 20px;
  }

  .service-price {
    font-size: var(--font-sm-1680);
  }

  .service img {
    width: 112px;
  }
}

@media only screen and (max-width: 1440px) {
  .service-text {
    font-size: var(--font-sub-header-1440);
  }

  .service-description {
    margin-top: 3px;
    margin-left: 15px;
  }

  .service-price {
    font-size: var(--font-sm-1440);
  }

  .service img {
    width: 96px;
  }
}

@media only screen and (max-width: 1280px) {
  .service-text {
    font-size: var(--font-sub-header-1280);
  }

  .service-description {
    margin-top: 2px;
    margin-left: 10px;
  }

  .service-price {
    font-size: var(--font-sm-1280);
  }

  .service img {
    width: 85px;
  }
}

@media only screen and (max-width: 1024px) {
  .service-text {
    font-size: var(--font-sub-header-1024);
  }

  .service-description {
    margin-top: 0;
    margin-left: 5px;
  }

  .service-price {
    font-size: var(--font-sm-1024);
  }

  .service img {
    width: 68.26px;
  }
}

@media only screen and (max-width: 768px) {
  .service {
    width: 40%;
  }
}