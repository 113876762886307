.ig-text-container:hover {
  background-color: #000000;
  opacity: 0.65;
}

.ig-text-container {
  opacity: 0;
  transition: opacity 0.2s;
  color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space:pre-wrap;
 
  z-index: 9;
}

.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ig-post {
  position: relative;
}

.ig-post a {
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .ig-text {
    display: none !important;
  }
}