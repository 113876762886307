@font-face {
  font-family: 'Verdana';
  src: url(assets/fonts/Sarabun-Thin.ttf) format('truetype');
  font-weight: 100;
}

.font-thin {
  font-family: 'Verdana';
  font-weight: 100;
}

@font-face {
  font-family: 'Verdana';
  src: url(assets/fonts/Sarabun-ThinItalic.ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}

.font-thin-italic {
  font-family: 'Verdana';
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Verdana';
  src: url(assets/fonts/Sarabun-ExtraLight.ttf) format('truetype');
  font-weight: 200;
}

.font-extra-light {
  font-family: 'Verdana';
  font-weight: 200;
}

@font-face {
  font-family: 'Verdana';
  src: url(assets/fonts/Sarabun-ExtraLightItalic.ttf) format('truetype');
  font-weight: 200;
  font-style: italic;
}

.font-extra-light-italic {
  font-family: 'Verdana';
  font-weight: 200;
}

@font-face {
  font-family: 'Verdana';
  src: url(assets/fonts/Sarabun-Light.ttf) format('truetype');
  font-weight: 300;
}

.font-light {
  font-family: 'Verdana';
  font-weight: 300;
}

@font-face {
  font-family: 'Verdana';
  src: url(assets/fonts/Sarabun-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

.font-light-italic {
  font-family: 'Verdana';
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Verdana';
  src: url(assets/fonts/Sarabun-Medium.ttf) format('truetype');
  font-weight: 400;
}

.font-medium {
  font-family: 'Verdana';
  font-weight: 400;
}

@font-face {
  font-family: 'Verdana';
  src: url(assets/fonts/Sarabun-MediumItalic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

.font-medium-italic {
  font-family: 'Verdana';
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Verdana';
  src: url(assets/fonts/Sarabun-Regular.ttf) format('truetype');
  font-weight: 500;
}

.font-regular {
  font-family: 'Verdana';
  font-weight: 500;
}

@font-face {
  font-family: 'Verdana';
  src: url(assets/fonts/Sarabun-Italic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

.font-regular-italic {
  font-family: 'Verdana';
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Verdana';
  src: url(assets/fonts/Sarabun-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

.font-semibold {
  font-family: 'Verdana';
  font-weight: 600;
}

@font-face {
  font-family: 'Verdana';
  src: url(assets/fonts/Sarabun-SemiBoldItalic.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

.font-semibold-italic {
  font-family: 'Verdana';
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Verdana';
  src: url(assets/fonts/Sarabun-Bold.ttf) format('truetype');
  font-weight: 700;
}

.font-bold {
  font-family: 'Verdana';
  font-weight: 700;
}

@font-face {
  font-family: 'Verdana';
  src: url(assets/fonts/Sarabun-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

.font-bold-italic {
  font-family: 'Verdana';
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Verdana';
  src: url(assets/fonts/Sarabun-ExtraBold.ttf) format('truetype');
  font-weight: 800;
}

.font-extrabold {
  font-family: 'Verdana';
  font-weight: 800;
}

@font-face {
  font-family: 'Verdana';
  src: url(assets/fonts/Sarabun-ExtraBoldItalic.ttf) format('truetype');
  font-weight: 800;
  font-style: italic;
}

.font-extrabold {
  font-family: 'Verdana';
  font-weight: 800;
  font-style: italic;
}

.jn-container {
  width: 100%;
  /* min-width: 1024px; */
  margin: 0 auto;
}

img {
  max-width: 100%;
}

:root {
  /* button */
  --font-btn-1920: 27px;
  --font-btn-1680: 23.625px;
  --font-btn-1440: 20.25px;
  --font-btn-1280: 18px;
  --font-btn-1024: 14.4px;

  /* header */
  /* 
  --font-header-1920: 60px;
  --font-header-1680: 52.5px;
  --font-header-1440: 45px;
  --font-header-1280: 40px;
  --font-header-1024: 32px; 
  */
  --font-header-1920: 40px;
  --font-header-1680: 35px;
  --font-header-1440: 30px;
  --font-header-1280: 26.67px;
  --font-header-1024: 21.33px; 

  /* sub-header */
  --font-sub-header-1920: 31px;
  --font-sub-header-1680: 27.125px;
  --font-sub-header-1440: 23.25px;
  --font-sub-header-1280: 20.67px;
  --font-sub-header-1024: 16.53px;
  --font-sub-header-768: 12px;

  /* text */
  /* 
  --font-text-1920: 30px;
  --font-text-1680: 26.25px;
  --font-text-1440: 22.5px;
  --font-text-1280: 20px;
  --font-text-1024: 16px; 
  */

  --font-text-1920: 20px;
  --font-text-1680: 17.5px;
  --font-text-1440: 15px;
  --font-text-1280: 13.33px;
  --font-text-1024: 10.6px;
  --font-text-768: 8px;

  /* small */
  --font-sm-1920: 25px;
  --font-sm-1680: 21.875px;
  --font-sm-1440: 18.75;
  --font-sm-1280: 16.67px;
  --font-sm-1024: 13.33px;

  /* horizontal-padding */
  --padding-x-1920: 212px;
  --padding-x-1680: 185.5px;
  --padding-x-1440: 159px;
  --padding-x-1280: 141.33px;
  --padding-x-1024: 90px;
  --padding-x-768: 30px;
}